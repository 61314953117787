<template>
  <div style="display: inline-block; white-space: nowrap;">
    <b-btn
      v-b-tooltip.hover
      variant="none"
      class="btn-none rounded-pill m-0 icone-acao p-0"
      title="Download"
      @click="baixaEmLoteLinha(gridProps.row)"
    >
      <feather-icon
        icon="DownloadIcon"
        class="text-primary"
        size="20"
      />
    </b-btn>
    <b-btn
      v-b-tooltip.hover
      title="Enviar por email"
      variant="none"
      class="btn-none icone-acao m-0 p-0"
      @click="openModalEnviarDocs()"
    >
      <feather-icon
        icon="SendIcon"
        class="text-primary"
        size="20"
      />
    </b-btn>
    <b-btn
      v-b-tooltip.hover
      title="Observação"
      variant="none"
      class="text-primary btn-none"
      style="padding: 0.2rem;"
      @click="openModalObservacao"
    >
      <font-awesome-icon
        icon="fa-regular fa-message"
        :class="{ 'text-warning': temObservacaoNova, 'text-primary': !temObservacaoNova }"
      />
    </b-btn>

    <envio-email
      ref="modalEnvioEmail"
      :title="`Enviar Documentos ${gridProps.row.razaoSocial}`"
      :sugestao="gridProps.row.email"
      :sugestao-email-adicional="gridProps.row.emailAdicionalEmpresa"
      @enviarEmail="enviarDocumentos"
    />
    <modal-observacao
      :ref="`modalObservacao${gridProps.row.id}`"
      v-model="gridProps.row"
      :api="api"
      :notificar="{ sucesso: notficarSucesso, erro: notificarErro }"
      @change="receberObservacao"
      @hidden="fecharObservacao($event)"
    />
  </div>
</template>

<style lang="scss">
</style>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '../../services/api'

export default {
  components: {
    FontAwesomeIcon,
    EnvioEmail: () => import('@/app/shared/components/envio-email/EnvioEmail.vue'),
    ModalObservacao: () => import('@/app/shared/components/modal-observacao/ModalObservacao.vue'),
  },
  props: {
    gridProps: {
      type: Object,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mostarArquivo: false,
      arquivo: {},
      mostrarPendencias: false,
      situacaoFiscalSelecionada: null,
      temObservacaoNova: false,
    }
  },
  watch: {
    temObservacaoNova(val, oldVal) {
      if (val !== oldVal) {
        this.val = val
      }
    },
  },
  mounted() {
    this.temObservacaoNova = this.gridProps.row.temObservacoesNaoLidas
  },
  methods: {
    abrirArquivoPendencia(linha) {
      this.mostrarPendencias = false
      this.abrirArquivo(linha)
    },
    abrirArquivo(linha) {
      if (!this.mostarArquivo && !this.mostrarPendencias) {
        this.situacaoFiscalSelecionada = linha
        // api.getURLDctfWeb(this.situacaoFiscalSelecionada.id)
        //   .then(payload => {
        //     this.arquivo.url = payload.data
        //     this.mostarArquivo = true
        //   })
        //   .catch(err => {
        //     this.fecharArquivo()
        //     this.notificarErro('Não foi possível abrir a visualização de arquivo.', err)
        //     throw err
        //   })
      }
    },
    fecharArquivo() {
      this.mostarArquivo = false
      this.situacaoFiscalSelecionada = null
      this.arquivo = {}
    },
    abrirPendencias(linha) {
      if (!this.temPendencia(linha)) {
        this.abrirArquivo(linha)
      } else if (!this.mostarArquivo && !this.mostrarPendencias) {
        this.situacaoFiscalSelecionada = linha
        this.mostrarPendencias = true
      }
    },
    fecharPendencias() {
      this.mostrarPendencias = false
      this.situacaoFiscalSelecionada = null
    },
    notficarSucesso(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'fa-solid fa-circle-check',
          variant: 'success',
        },
      })
    },
    notificarErro(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'fa-solid fa-circle-exclamation',
          variant: 'danger',
        },
      })
    },
    openModalEnviarDocs() {
      this.$refs.modalEnvioEmail.abrirModal()
    },
    enviarDocumentos(itens) {
      if (itens.emailsPara.length === 0 && itens.emailsCc.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Informe ao menos um e-mail',
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
      } else {
        this.$emit('enviar-email', { linha: this.gridProps.row, email: itens })
      }
    },
    baixaEmLoteLinha(linha) {
      this.$emit('baixa-em-lote', linha.id)
    },
    salvarObservacao() {
      api.salvarObservacao(this.gridProps.row.id, this.gridProps.row.observacao)
    },
    openModalObservacao() {
      this.$refs[`modalObservacao${this.gridProps.row.id}`].show()
    },
    fecharObservacao(observacaoNova) {
      this.temObservacaoNova = observacaoNova.hasObservacaoNova
      this.gridProps.row.observacao = ''
    },
    receberObservacao(valor) {
      this.gridProps.row.observacao = valor
    },
  },
}
</script>
